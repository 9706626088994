<template>
  <v-container fluid :class="{ 'white py-10': !modal }">
    <div v-show="!noTitle" class="secondary--text text-h4 text-center pb-6">
      Download the app
    </div>
    <v-row v-show="modal" no-gutters justify="center">
      <v-col cols="12" align="center">
        <v-row v-if="!noDivider" align="center" justify="center">
          <v-col cols="5">
            <v-divider class="my-6"></v-divider>
          </v-col>
          <v-col cols="2" md="1">
            <span>Or</span>
          </v-col>
          <v-col cols="5">
            <v-divider class="my-6"></v-divider>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-show="!noSms" cols="12">
        <p
          class="font-weight-light ma-3 text-uppercase"
          style="font-size: 11px;"
          align="center"
        >
          Enter your phone number to receive a download link
        </p>
        <v-row
          no-gutters
          class="align-start justify-start"
          style="max-width: 480px;"
        >
          <v-col
            cols="12"
            md="9"
            sm="9"
            xs="12"
            align="center"
            style="max-height: 40px;"
          >
            <vue-tel-input-vuetify
              id="phone-input"
              v-model="phone"
              outlined
              dense
              persistent-hint
              disabled-fetching-country
              :label="label"
              :append-icon="append_icon"
              :mode="mode"
              required
              :placeholder="placeholder"
              :error-messages="error_phone_message"
              :error="invalid_phone"
              :input-options="input_options"
              :default-country="default_country"
              :only-countries="countries_list"
              @input="validatePhoneNumber"
              @keydown="keyHandler"
            ></vue-tel-input-vuetify>
          </v-col>
          <v-col cols="12" md="3" sm="3" xs="12" align="center">
            <v-btn
              id="sms-send-button"
              depressed
              x-medium
              tile
              color="secondary"
              class="text-capitalize input-button"
              style="max-height: 40px; border-radius: 5px;"
              :disabled="invalid_phone"
              @click="sendSMS"
            >
              Send Link
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-row
        no-gutters
        class="mb-4 app-buttons-container align-center justify-space-around"
        style="max-width: 480px;"
      >
        <AppStoreButton />
        <PlayStoreButton />
      </v-row>
    </v-row>

    <v-row v-show="!modal" no-gutters justify="center">
      <v-col
        cols="12"
        :align="$vuetify.breakpoint.smAndDown || modal ? 'center' : ''"
      >
        <p
          v-show="!noTitle"
          class="font-weight-light ma-3 text-uppercase"
          style="font-size: 11px;"
          align="center"
        >
          Enter your phone number to receive a download link
        </p>
      </v-col>
      <v-row
        no-gutters
        align="center"
        justify="center"
        class="mb-4 app-buttons-container"
        style="max-width: 480px;"
      >
        <v-col cols="6">
          <AppStoreButton />
        </v-col>
        <v-col cols="6">
          <PlayStoreButton />
        </v-col>
      </v-row>
    </v-row>

    <v-snackbar
      v-model="showMessage"
      color="blue-grey"
      right
      rounded="pill"
      bottom
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AppStoreButton from '@/components/AppStoreButton'
import PlayStoreButton from '@/components/PlayStoreButton'
import smsMixin from '@/mixins/smsMixin'
import phoneNumberMixin from '@/mixins/phoneNumberMixin'

export default {
  name: 'AppDownload',
  components: { AppStoreButton, PlayStoreButton },
  mixins: [smsMixin, phoneNumberMixin],
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    noDivider: {
      type: Boolean,
      default: false,
    },
    noSms: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    region: '+1',
    phone: '',
    showMessage: false,
    message: null,
    loading: false,
  }),
  computed: {
    ...mapState('user', ['profile']),
  },
  mounted() {
    if (this.noSms) {
      this.phone = this.profile.phone

      this.region = `+${this.profile.country_code}`
    }
    this.invalid_phone = true
  },
  methods: {
    sendSMS() {
      if (this.clean_phone_number !== '' && this.clean_country_code !== '')
        this.sendDownloadLink(
          `+${this.clean_country_code}`,
          this.clean_phone_number
        ).then(
          () => {
            this.phone = null
            this.clean_phone_number = ''
            this.clean_country_code = ''
            this.invalid_phone = true
            this.openDialog = false

            this.showMessage = true
            this.message = 'The SMS has been sent!'
            this.loading = false
          },
          (error) => {
            this.showMessage = true
            this.message = error.message
            this.loading = false
          }
        )
    },
  },
}
</script>

<style lang="scss" scoped>
.input-number {
  background-color: #d1e8ec;
}
.input-button {
  height: 55.5px !important;
}
.v-input__append-outer {
  margin: 0 !important;
}
#sms-send-button {
  background-color: #276b6c !important;
  border-color: #276b6c !important;
  color: white !important;
}
.vue-tel-input-vuetify .v-text-field__details {
  display: none;
}
.app-buttons-container {
  margin-top: 24px;
}
@media (max-width: 600px) {
  #sms-send-button {
    margin-top: 12px;
  }
}
@media (max-width: 471px) {
  .app-buttons-container {
    margin-top: 36px;
  }
}
</style>
