import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    phone: '',
    default_country: 'US',
    countries_list: ['US', 'CO'],
    invalid_phone: false,
    error_phone_message: '',
    clean_phone_number: '',
    clean_country_code: '',
    append_icon: 'mdi-chevron-down',
    mode: 'national',
    label: 'Phone Number',
    placeholder: '',
    input_options: { showDialCode: false, tabindex: 0 },
  }),
  methods: {
    keyHandler(event) {
      if (event.key === '+') {
        event.preventDefault()
      }
    },
    validatePhoneNumber(phoneText, phoneObj) {
      if (!phoneObj.valid) {
        if (phoneObj.number.input !== '') {
          this.invalid_phone = true
          this.error_phone_message = 'Invalid phone Number.'
        } else {
          this.invalid_phone = false
          this.error_phone_message = ''
        }
        this.clean_phone_number = ''
        this.clean_country_code = ''
      } else {
        this.invalid_phone = false
        this.error_phone_message = ''
        this.clean_phone_number = phoneObj.number.significant
        this.clean_country_code = phoneObj.country.dialCode
      }
    },
  },
})
